.indent {
  margin-left: 20px;
}

.folder {
  cursor: pointer;
  text-decoration: none;
}

.folder:hover {
  text-decoration: underline;
}

.script {
  cursor: pointer;
  text-decoration: none;
}

.script:hover {
  text-decoration: underline;
}
