body {
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

div.row,
div.container {
  margin: 0;
  padding: 0;
}

.test {
  margin-top: 100vh;
}

.parent {
    display: flex;
    min-height: calc(100vh - 40px);
    flex-direction: column;
}