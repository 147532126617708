.navigation {
  width: 500px;
  height: 100%;
}

.menu-item {
  border-bottom: 1px solid white;
  text-decoration: none;
}

.menu-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.contextMenu {
  border-radius: 10px;
}
